@import "./fonts";

:root {
    --primary-color: #70BD95;
    --selected-item-color: #002439;
    --secondary-color: #002439;
    --ternary-color: #002439;
    --timer-color: #70BD95;
}

* {
    margin: 0;
    padding: 0;
    outline: none !important;
    backface-visibility: hidden;
    /* iOS Safari */
    -webkit-touch-callout: none;
    /* Safari */
    -webkit-user-select: none;
    /* Konqueror HTML */
    -khtml-user-select: none;
    /* Old versions of Firefox */
    -moz-user-select: none;
    /* Internet Explorer/Edge */
    -ms-user-select: none;
    user-select: none;
}

body,
html {
    font-size: 10px;
    font-family: gotham;
    font-weight: 400;
    color: var(--primary-color);
}

@mixin setGlobalFontSize {
    $resolution: 800;

    @while $resolution < 4000 {
        @media (min-width: #{$resolution}px) {
            body,
            html {
                font-size: calc($resolution * 10 / 1920) * 1px;
            }
        }
        
        $resolution: $resolution + 20;
    }
}

@include setGlobalFontSize;

a,
a:hover,
a:active,
a.active,
a:visited {
    color: inherit;
    text-decoration: none;
    outline: none;
    cursor: pointer;
}








.menu_wrapper {
    width: 100%;
    height: 100vh;
    background-color: #030F19;

    & .logo {
        & img {
            width: 95%;
        }
    }

    & .bg_setup {
        background-image: url('./assets/images/bg-vr.png');
        background-position: right center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        & .timer {
            position: absolute;
            bottom: 3rem;
            right: 4rem;
            text-align: right;

            & .time {
                font-size: 6rem;
                color: var(--timer-color);
                line-height: 6.6rem;
                font-weight: 500;
                text-shadow: 0px 3px 17px rgba(0, 0, 0, 0.16);
            }

            & .day_info {
                font-size: 3rem;
                line-height: 3.2rem;
                color: var(--timer-color);
                text-shadow: 0px 3px 17px rgba(0, 0, 0, 0.16);
            }
        }
    }

    & .menu_section {
        padding: 4rem 0 3rem 5.6rem;
        background-color: #EBF3FA;
        position: relative;
        z-index: 22;
        min-width: 68rem;
        width: 68rem;
        transition: .2s ease-in-out;

        &::after {
            content: "";
            position: absolute;
            right: -5.6rem;
            top: 0;
            width: 5.6rem;
            height: 100%;
            background-color: #EBF3FA;
            border-radius: 0 5rem 5rem 0;
            z-index: -1;
            transition: .2s ease-in-out;
        }



        & .side_menu {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & .body_section {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 100%;

                & ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    & li {
                        padding: 20px 25px;
                        transition: .2s ease-in-out;

                        & a,
                        button {
                            width: 100%;
                            border: 0px;
                            background: linear-gradient(243deg, #FFFFFF 0%, #FCFCFD 30%, #F2F5F7 52%, #E3E8ED 70%, #CDD6DF 87%, #B8C5D2 100%);
                            box-shadow: 10px 10px 15px #BCCDDD,
                                -10px -10px 15px #ffffff;
                            transition: .3s ease-in-out;
                            // background: #000;
                            display: block;
                            border-radius: 1.4rem;
                            padding: 1px;

                            & .content_card_wrper {
                                display: flex;
                                background: linear-gradient(81deg, #DBE6F3 0%, #EDF3FA 100%);
                                border-radius: 1.4rem;
                                padding: 2rem;
                                overflow: hidden;
                                align-items: center;
                                position: relative;
                                transition: .2s ease-in-out;


                                & .icon {
                                    flex: none;

                                    & img {
                                        width: 9.5rem;
                                        transition: .2s ease-in-out;
                                    }
                                }

                                & .right_side_block {
                                    margin-left: 2rem;
                                    text-align: left;

                                    & h1 {
                                        font-size: 4rem;
                                        font-weight: 600;
                                        color: var(--primary-color);
                                        line-height: 4.4rem;
                                        margin-bottom: 1rem;
                                        transition: .2s ease-in-out;
                                    }

                                    & p {
                                        font-size: 1.6rem;
                                        line-height: 1.8rem;
                                        color: #464646;
                                        transition: .2s ease-in-out;
                                        margin: 0;
                                    }
                                }

                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    right: 3rem;
                                    transform: translateY(-50%);
                                    width: 1.5rem;
                                    height: 2.5rem;
                                    background-image: url('./assets/images/arrow.svg');
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    background-position: center;
                                    transition: .2s ease-in-out;
                                }
                            }

                            @media (hover: hover) {
                                &:active {
                                    background: linear-gradient(81deg, #DBE6F3 0%, #EDF3FA 100%);
                                    box-shadow: inset 5px 5px 10px #BCCDDD,
                                        inset -5px -5px 10px #ffffff;

                                    & .content_card_wrper {
                                        background: transparent;
                                    }
                                }
                            }

                            @media (hover: none) {
                                &:hover {
                                    background: linear-gradient(81deg, #DBE6F3 0%, #EDF3FA 100%);
                                    box-shadow: inset 5px 5px 10px #BCCDDD,
                                        inset -5px -5px 10px #ffffff;

                                    & .content_card_wrper {
                                        background: transparent;
                                    }
                                }
                            }

                        }

                        &.disabled_feature {
                            pointer-events: none;
                            filter: saturate(0.3);
                            opacity: 0.8;
                        }
                    }
                }
            }

            & .footer_section {
                text-align: center;

                & .assistence_sec {
                    display: inline-block;
                    width: 41rem;
                    max-width: 99%;
                    padding: 2.5rem;
                    text-align: center;
                    background-color: var(--primary-color);
                    color: #fff;
                    border-radius: 1.4rem;
                    margin-bottom: 6rem;
                    box-shadow: 10px 10px 15px #BCCDDD;

                    & p {
                        font-size: 2rem;
                        line-height: 2.1rem;
                        text-transform: uppercase;
                        color: #fff;
                        margin-bottom: 2rem;
                        font-weight: 500;
                    }

                    & ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        display: inline-flex;

                        & li {
                            &:not(:last-child) {
                                margin-right: 2rem;
                            }

                            & button {
                                outline: none;
                                border: 0px;
                                background-color: transparent;

                                & img {
                                    width: 2.5rem;
                                    transform: scale(1);
                                    transition: .2s ease-in-out;
                                }

                                &:hover {
                                    & img {
                                        transform: scale(1.4);
                                    }
                                }
                            }
                        }
                    }
                }

                & .copy_right {
                    color: #464646;
                    font-size: 1.4rem;
                    line-height: 1.5rem;
                    font-weight: 500;
                }
            }
        }
    }



    &.inner_page {

        & .menu_section {
            padding: 4rem 0 3rem 4rem;
            min-width: 46.6rem;
            width: 46.6rem;

            &::after {
                right: -4.6rem;
                width: 4.6rem;
            }

            &.hide_menu {
                width: 0;
                min-width: 0;
                padding: 0;
                overflow: hidden;

                &::after {
                    right: 0;
                }
            }

            & .side_menu {

                & .body_section {
                    width: calc(100% + 20px);
                    ;

                    & ul {

                        & li {
                            padding: 20px 25px 20px 10px;

                            & a,
                            button {

                                & .content_card_wrper {
                                    padding: 2.5rem 1.8rem;


                                    & .icon {
                                        & img {
                                            width: 6.5rem;
                                        }
                                    }

                                    & .right_side_block {

                                        & h1 {
                                            font-size: 3.5rem;
                                            line-height: 3.9rem;
                                            margin-bottom: 0;
                                            font-weight: 500;
                                            color: #464646;
                                        }

                                        & p {
                                            font-size: 0;
                                            opacity: 0;
                                            line-height: 0;
                                        }
                                    }

                                    &::after {
                                        right: 3rem;
                                        width: 0.8rem;
                                        height: 1.6rem;
                                    }
                                }

                                &.active {
                                    background: linear-gradient(81deg, #DBE6F3 0%, #EDF3FA 100%);
                                    box-shadow: inset 5px 5px 10px #BCCDDD,
                                        inset -5px -5px 10px #ffffff;

                                    & .content_card_wrper {
                                        background: transparent;
                                    }

                                    & .content_card_wrper {

                                        & .right_side_block {
                                            h1 {
                                                color: var(--selected-item-color);
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}




// Dialer page setup
.dialer_wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #030F19;
    padding-left: calc(7.5rem + 50px);
    display: flex;
    align-items: center;

    & .dialer_outer_wrapper {
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        position: relative;
        // justify-content: center;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 97rem;
            height: 100%;
            background-image: url('./assets/images/rays-bg.svg');
            background-repeat: no-repeat;
            background-position: right;
            background-size: contain;
        }

        & .top_gradient {
            position: absolute;
            top: 0;
            left: -10vw;
            width: 100vw;
            height: 25rem;
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #020D17 50%, #000000 100%);
            background-repeat: no-repeat;
            background-position: right;
            background-size: contain;
            opacity: 0.53;
        }

        & .bottom_gradient {
            position: absolute;
            bottom: 0;
            left: -10vw;
            width: 100vw;
            height: 25rem;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #020D17 50%, #000000 100%);
            background-repeat: no-repeat;
            background-position: right;
            background-size: contain;
            opacity: 0.53;
        }

        & .dialer_outer {
            position: relative;
            width: 62rem;
            height: 62rem;
            border-radius: 50%;
            background-color: #061420;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 10px 12px rgba(#030F19, 0.502);

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 57rem;
                height: 57rem;
                background: linear-gradient(180deg, #112C41, #020C14);
                box-shadow: 0px -2px 0px rgba(0, 159, 195, 0.31);
                border-radius: 50%;
            }

            & .active_head {
                display: block;
                position: absolute;
                width: calc(50% - 2.5rem);
                // background: red;
                text-align: right;
                top: 50%;
                left: 50%;
                transform: translateY(-50%);

                & .active_hd {
                    display: inline-block;
                    width: 9.7rem;
                    height: 6.8rem;

                    background-image: url('./assets/images/dialer_active.svg');
                    background-repeat: no-repeat;
                    background-position: right;
                    background-size: contain;
                }
            }


            & .alphas {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: #EFF0F3;
                font-size: 2rem;
                // transition: .05s ease-in-out;
                // transform: rotate(0turn);

                & span.letter_wrapper {
                    display: block;
                    position: absolute;
                    width: calc(50% - 2.5rem);
                    // background: red;
                    text-align: right;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) rotate(0deg);
                    transform-origin: center left;
                    // transition: .1s linear;
                    backface-visibility: hidden;

                    & .letter {
                        display: inline-block;
                        width: 8.5rem;
                        text-align: center;
                        transform: rotate(0deg);
                        // transition: .1s linear;
                        backface-visibility: hidden;
                        color: rgba(255, 255, 255, 1);
                        text-shadow: 0 0 0px rgba(255, 255, 255, 0.2);
                    }

                    &.rotate_0 {
                        transform: translateY(-50%) rotate(0);

                        & .letter {
                            transform: rotate(0deg);
                        }
                    }

                    // &.transition_none {
                    //     transition: none;

                    //     & .letter {
                    //         transition: none;
                    //     }
                    // }

                    &.set_to_stay {
                        transform: translateY(-50%) rotate(360);

                        & .letter {
                            transform: rotate(-360deg);
                        }
                    }

                    &.rotate_1 {
                        transform: translateY(-50%) rotate(13.8461deg);

                        & .letter {
                            transform: rotate(-13.8461deg);
                        }
                    }

                    &.rotate_2 {
                        transform: translateY(-50%) rotate(27.6922deg);

                        & .letter {
                            transform: rotate(-27.6922deg);
                        }
                    }

                    &.rotate_3 {
                        transform: translateY(-50%) rotate(41.5383deg);

                        & .letter {
                            transform: rotate(-41.5383deg);
                        }
                    }

                    &.rotate_4 {
                        transform: translateY(-50%) rotate(55.3844deg);

                        & .letter {
                            transform: rotate(-55.3844deg);
                        }
                    }

                    &.rotate_5 {
                        transform: translateY(-50%) rotate(69.2305deg);

                        & .letter {
                            transform: rotate(-69.2305deg);
                        }
                    }

                    &.rotate_6 {
                        transform: translateY(-50%) rotate(83.0766deg);

                        & .letter {
                            transform: rotate(-83.0766deg);
                        }
                    }

                    &.rotate_7 {
                        transform: translateY(-50%) rotate(96.9227deg);

                        & .letter {
                            transform: rotate(-96.9227deg);
                        }
                    }

                    &.rotate_8 {
                        transform: translateY(-50%) rotate(110.7688deg);

                        & .letter {
                            transform: rotate(-110.7688deg);
                        }
                    }

                    &.rotate_9 {
                        transform: translateY(-50%) rotate(124.6149deg);

                        & .letter {
                            transform: rotate(-124.6149deg);
                        }
                    }

                    &.rotate_10 {
                        transform: translateY(-50%) rotate(138.461deg);

                        & .letter {
                            transform: rotate(-138.461deg);
                        }
                    }

                    &.rotate_11 {
                        transform: translateY(-50%) rotate(152.3071deg);

                        & .letter {
                            transform: rotate(-152.3071deg);
                        }
                    }

                    &.rotate_12 {
                        transform: translateY(-50%) rotate(166.1532deg);

                        & .letter {
                            transform: rotate(-166.1532deg);
                        }
                    }

                    &.rotate_13 {
                        transform: translateY(-50%) rotate(179.9993deg);

                        & .letter {
                            transform: rotate(-179.9993deg);
                        }
                    }

                    &.rotate_14 {
                        transform: translateY(-50%) rotate(193.8454deg);

                        & .letter {
                            transform: rotate(-193.8454deg);
                        }
                    }

                    &.rotate_15 {
                        transform: translateY(-50%) rotate(207.6915deg);

                        & .letter {
                            transform: rotate(-207.6915deg);
                        }
                    }

                    &.rotate_16 {
                        transform: translateY(-50%) rotate(221.5376deg);

                        & .letter {
                            transform: rotate(-221.5376deg);
                        }
                    }

                    &.rotate_17 {
                        transform: translateY(-50%) rotate(235.3837deg);

                        & .letter {
                            transform: rotate(-235.3837deg);
                        }
                    }

                    &.rotate_18 {
                        transform: translateY(-50%) rotate(249.2298deg);

                        & .letter {
                            transform: rotate(-249.2298deg);
                        }
                    }

                    &.rotate_19 {
                        transform: translateY(-50%) rotate(263.0759deg);

                        & .letter {
                            transform: rotate(-263.0759deg);
                        }
                    }

                    &.rotate_20 {
                        transform: translateY(-50%) rotate(276.922deg);

                        & .letter {
                            transform: rotate(-276.922deg);
                        }
                    }

                    &.rotate_21 {
                        transform: translateY(-50%) rotate(290.7681deg);

                        & .letter {
                            transform: rotate(-290.7681deg);
                        }
                    }

                    &.rotate_22 {
                        transform: translateY(-50%) rotate(304.6142deg);

                        & .letter {
                            transform: rotate(-304.6142deg);
                        }
                    }

                    &.rotate_23 {
                        transform: translateY(-50%) rotate(318.4603deg);

                        & .letter {
                            transform: rotate(-318.4603deg);
                        }
                    }

                    &.rotate_24 {
                        transform: translateY(-50%) rotate(332.3064deg);

                        & .letter {
                            transform: rotate(-332.3064deg);
                        }
                    }

                    &.rotate_25 {
                        transform: translateY(-50%) rotate(346.1525deg);

                        // transition: none;
                        & .letter {
                            transform: rotate(-346.1525deg);
                            // transition: none;
                        }
                    }
                }
            }



            & .dialer_base {
                flex: none;
                width: 40rem;
                height: 40rem;
                border-radius: 50%;
                background-image: url('./assets/images/pattern-bg.svg'), linear-gradient(180deg, #020C14, #112C41);
                background-size: 90%, cover;
                background-position: center;
                background-repeat: no-repeat;
                box-shadow: 0px 2px 0px rgba(0, 159, 195, 0.31);
                position: relative;


                &::after {
                    content: "";
                    position: absolute;
                    top: 40%;
                    right: 5rem;
                    width: 2.1rem;
                    height: 2.1rem;
                    transform: translate(-50%, -50%);
                    background-image: url('./assets/images/direction_arrow.svg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    opacity: 0.6;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 40%;
                    right: 5rem;
                    width: 2.1rem;
                    height: 2.1rem;
                    transform: translate(-50%, 50%);
                    background-image: url('./assets/images/direction_arrow_1.svg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    opacity: 0.6;
                }

                & .person_img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 17rem;
                    height: 17rem;
                    transform: translate(-50%, -50%);
                    background-image: url('./assets/images/icon_people_bg.svg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    opacity: 0.6;
                }

            }

            &.animate_dialer {
                & .alphas {
                    // transform: rotate(1turn);
                    animation: anim .5s linear 0s infinite forwards;
                    animation-direction: reverse;

                    & span.letter_wrapper {

                        & .letter {
                            color: rgba(255, 255, 255, 0);
                            text-shadow: 0 0 8px rgba(255, 255, 255, 0.5), 2px 2px 10px rgba(255, 255, 255, 0.1);
                            transform: scaley(2);
                            transform-origin: left;
                        }
                    }
                }

                &.extend_blur {
                    & .alphas {

                        & span.letter_wrapper {

                            & .letter {
                                text-shadow: 0 0 8px rgba(255, 255, 255, 0.055), 2px 2px 10px rgba(255, 255, 255, 0.05);
                                transform: scaley(2);
                            }
                        }
                    }
                }
            }
        }


        & .contacts_outer {
            margin-left: 1.5rem;
            height: 100%;
            position: relative;

            & ul {
                list-style: none;

                & li {
                    & a.contact_content_wrapper {
                        display: block;
                        width: 37.3rem;
                        height: 8.8rem;
                        position: absolute;
                        top: 50%;
                        opacity: 1;
                        transform: translate(0, -50%);
                        backface-visibility: hidden;
                        transition: .3s linear;

                        & .contact_content_block {
                            background-color: #DBE6F3;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            border-radius: 4.4rem;
                            box-shadow: 3px 5px 6px #04101C;
                            padding: 1.5rem;
                            display: flex;
                            align-items: center;

                            & .user_image {
                                width: 5.5rem;
                                height: 5.5rem;
                                border-radius: 50%;
                                background-image: url('./assets/images/business-woman.png');
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                flex: none;
                                margin-right: 2rem;
                                position: relative;

                                & .status {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: 1.3rem;
                                    height: 1.3rem;
                                    background-color: #32D152;
                                    border-radius: 50%;

                                    &.off {
                                        background-color: #DE0000;
                                    }
                                }
                            }

                            & .contact_content {
                                flex: 1;
                                width: 29rem;

                                & p {
                                    color: #272727;
                                    font-size: 1.8rem;
                                    line-height: 2rem;
                                    font-weight: 500;
                                    margin-bottom: 0;
                                }

                                & .block_content {
                                    color: #464646;
                                    display: inline-block;
                                    font-size: 1.4rem;
                                    line-height: 1.5rem;
                                    white-space: nowrap;
                                    max-width: calc(100% - 2rem);
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    margin-top: 1rem;
                                }
                            }
                        }

                        & .contact_content_active {

                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 4.4rem;
                            padding: 1.5rem;
                            background-color: var(--selected-item-color);
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            & a,
                            button {
                                outline: none;
                                border: 0px;
                                padding: 1rem;
                                background: transparent;

                                & img {
                                    width: 3.5rem;
                                }

                                &:not(:last-child) {
                                    margin-right: 0.5rem;
                                }
                            }
                        }

                        &.m1 {
                            transform: translate(-1.4rem, calc(-150% - 1rem));
                        }

                        &.m2 {
                            transform: translate(-5.3rem, calc(-250% - 2rem));
                        }

                        &.m3 {
                            transform: translate(-15rem, calc(-350% - 3rem));
                        }

                        &.m4 {
                            transform: translate(-24rem, calc(-450% - 4rem));

                            & .contact_content_block {
                                background-image: linear-gradient(0deg, #DBE6F3 0%, #68727D 100%);
                            }
                        }

                        &.mout {
                            opacity: 0;
                            transform: translate(-38rem, calc(-550% - 4rem));
                        }

                        &.p1 {
                            transform: translate(-1.4rem, calc(50% + 1rem));
                        }

                        &.p2 {
                            transform: translate(-5.3rem, calc(150% + 2rem));
                        }

                        &.p3 {
                            transform: translate(-15rem, calc(250% + 3rem));
                        }

                        &.p4 {
                            transform: translate(-24rem, calc(350% + 4rem));

                            & .contact_content_block {
                                background-image: linear-gradient(180deg, #DBE6F3 0%, #747E8A 100%);
                            }
                        }

                        &.pout {
                            opacity: 0;
                            transform: translate(-38rem, calc(450% + 4rem));
                        }

                        &.active {
                            & .contact_content_block {
                                box-shadow: 0px 0px 0px #04101C;
                            }

                            & .contact_content_active {
                                right: -19rem;
                                transition: .2s linear;
                                transition-delay: .5s;

                                &.disable_features a {
                                    opacity: 0.4;
                                    pointer-events: none;
                                    cursor: not-allowed;
                                }
                            }
                        }


                    }
                }
            }
        }


    }
}

@keyframes anim {
    0% {
        transform: rotate(0turn);
    }

    100% {
        transform: rotate(1turn);
    }
}

.search_filter {
    position: fixed;
    top: 50%;
    right: 4rem;
    transform: translateY(-50%);

    & img {
        width: 4rem;
    }
}

.arrow_down {
    position: fixed;
    top: 50%;
    right: 24rem;
    transform: translateY(calc(-50% + 8.4rem));

    & img {
        width: 4rem;
    }
}

.arrow_up {
    position: fixed;
    top: 50%;
    right: 24rem;
    transform: translateY(calc(-50% - 8.4rem));

    & img {
        width: 4rem;
    }
}

.dialer_info {
    position: fixed;
    top: 4rem;
    right: 5rem;
    display: flex;
    align-items: center;

    & img {
        flex: none;
        cursor: pointer;
        width: 3.2rem;
        margin-left: 3rem;
    }

    & ul {
        list-style: none;
        margin: 0;
        display: flex;
        transform: scale(0);
        transform-origin: right;
        opacity: 0;
        transition: .2s ease-in-out;

        &.active {
            transform: scale(1);
            opacity: 1;
        }

        & li {
            padding-left: 2.3rem;
            font-size: 1.4rem;
            color: #ACACAC;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1.3rem;
                height: 1.3rem;
                border-radius: 50%;
                background-color: #32D152;

            }

            &.busy {
                &::before {
                    background-color: #DE0000;
                }
            }

            &:not(:first-child) {
                margin-left: 2rem;
            }
        }
    }
}