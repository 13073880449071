@font-face {
    font-family: gotham;
    src: url('./assets/fonts/Gotham-Book.otf');
    font-weight: 400;
}
@font-face {
    font-family: gotham;
    src: url('./assets/fonts/Gotham-Medium.otf');
    font-weight: 500;
}
@font-face {
    font-family: gotham;
    src: url('./assets/fonts/Gotham-Bold.otf');
    font-weight: 600;
}
@font-face {
    font-family: gotham;
    src: url('./assets/fonts/Gotham-Light.otf');
    font-weight: 300;
}